<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>All SchoolPay applications</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ All SchoolPay applications
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="School name"
                      v-model="search.name"
                      clearable
                      outlined
                      v-on:keyup.enter="getAllSchools()"
                      @input="search.name = $event !== null ? $event : ''" 
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Search By email, contact person email "
                      v-model="search.user_info"
                      clearable
                      outlined
                      dense
                      v-on:keyup.enter="getAllSchools()"
                      @input="search.user_info = $event !== null ? $event : ''"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.approved_date"
                          label="Approved date"
                          clearable
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.approved_date"
                        outlined
                        dense
                        no-title
                        @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                    <v-text-field
                        v-model="search.approved_by"
                        label="Approved by"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Application status"
                      v-model="search.application_status"
                      :disabled="currentStatus"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click.prevent="searchSchools"
                      class="btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;" :class="sortedClass('school_name')" @click="sortBy('school_name')">School Name</th>
                  <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">User</th>
                  <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">Email</th>
                  <th class="px-3">Phone</th>
                  <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">Address Line 1</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Decision maker</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="school in schools" :key="school.id" >
                    <td class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">
                      <span @click="editSchoolPay(school.id)" :class="currentUser && currentUser.access_type != 'score' ? 'text-primary font-weight-bolder text-hover-primary mb-1 cursor-pointer' : ''">
                        {{ school.school_name }}
                      </span>
                    </td>
                    <td class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">
                      {{ school.user ? school.user : '--' }}
                    </td>
                    <td style="max-width: 120px !important; white-space: pre-wrap;">
                      {{ school.email }}
                    </td>
                    <td>
                      {{ school.phone }}
                    </td>
                    <td style="max-width: 120px !important; white-space: pre-wrap;">
                      {{ school.address_line_1 }}
                    </td>
                    <td>
                      {{ school.score_prefix ? school.score_prefix : 'NA'}}
                    </td>

                    <td>
                      <div class="mb-3 ">
                        <!-- <b>Application status: </b> -->
                        <span class="badge"
                                v-bind:class="{ 'badge-success': school.application_status == 'approved', 'badge-warning': school.application_status == 'pending',
                            'bodge-danger': school.application_status == 'rejected', 'bodge-info': school.application_status == 'need_further_info'  }"
                            >{{ school.applicationStatus }}</span>
                      </div>
                      <!-- <div>
                        <b>State: </b><span class="badge badge-success"
                                            v-bind:class="{ 'badge-success': school.score_approval == 'approved', 'badge-warning': school.score_approval == 'not_approved' }"
                        >{{ school.score_approval_text }}</span>
                      </div> -->
                    </td>
                    <td>
                      <span v-if="school.approvedBy">{{school.approvedBy}}<br/>
                        <span>on {{school.formatted_approved_date}}</span></span>
                      <span v-else>NA</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template v-if="school.application_status == 'pending' && currentUser && currentUser.access_type ==  'score' && school.scoreApprovalDecisionBy == null">
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="approvedOrReject(school)" class="navi-link" >
                                <span class="navi-icon">
                                  <i class="fas fa-info-circle"></i>
                                </span>
                                <span class="navi-text">Approve/Not approved</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                        </template>
                        <template v-if="school.application_status == 'pending' && currentUser && currentUser.access_type == 'score' && school.scoreApprovalDecisionBy != null">
                          <span>NA</span>
                        </template>

                        <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                            v-if="school.application_status == 'pending' && currentUser && currentUser.access_type !=  'score'"
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="approvedOrReject(school)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-info-circle"></i>
                                </span>
                                <span class="navi-text">Approve/Not approved</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSchoolPay(school.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"  v-if="currentUser && currentUser.access_type !=  'score'">
                              <a class="navi-link" @click.prevent="deleteSchoolPay(school.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="schools.length == 0" >
                    <td colspan="11" class="text-center"><strong>No pending SchoolPay applications found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="schools.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSchools"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
        <approved-or-rejected ref="approved-or-rejected" @refresh="getAllSchools"></approved-or-rejected>
        <score-approved-or-rejected ref="score-approved-or-rejected" @refresh="getAllSchools"></score-approved-or-rejected>
      </div>
    </div>
  </v-app>
</template>
<script>
import SchoolPayApplicationService from "@/services/school-pay/SchoolPayApplicationService";
import ApprovedOrRejected from "@/view/pages/view/school/pay/ApprovedOrRejected";
import ScoreApprovedOrRejected from "@/view/pages/view/school/pay/ScoreApprovedOrRejected";

const schoolPayApplication = new SchoolPayApplicationService();

export default {
  components: {ScoreApprovedOrRejected, ApprovedOrRejected},
  data() {
    return {
      menu: false,
      loading: false,
      statusDisable: false,
      currentStatus: false,
      total: null,
      perPage: null,
      page: null,
      schools: [],
      currentUser: {},
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        { name: "Approved", value: "approved" },
        { name: "Pending", value: "pending" },
        { name: "Rejected", value: "rejected" },
        { name: "Need Further Info", value: "need_further_info" },
      ],
      search: {
        name: '',
        user_info: '',
        approved_date: '',
        approved_by: '',
        application_status: ''
      }
    }
  },
  mounted(){
    // if(this.$route.params.application_status == 'pending') {
    //   this.currentStatus = true;
    //   this.search.application_status = this.$route.params.application_status;
    // }
    this.getAllSchools();
    this.getCurrentUser();
  },
  computed: {
    sortedItems() {
      const list = this.schools.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllSchools() {
      this.loading = true;
      schoolPayApplication
          .paginate(this.search, this.page)
          .then((response) => {
            this.schools = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    searchSchools() {
      this.page = 1;
      this.getAllSchools();
    },
    editSchoolPay(schoolId) {
      if(this.currentUser && this.currentUser.access_type != 'score') {
        this.$router.push({
          name: 'school-pay-application-edit',
          params: { schoolId: schoolId }
        });
      }
    },
    approvedOrReject(school) {
     if(this.currentUser && this.currentUser.access_type == 'score') {
       this.$refs['score-approved-or-rejected'].openDialog(school);
     }else  {
       this.$refs['approved-or-rejected'].openDialog(school);
     }
    },
    deleteSchoolPay(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            schoolPayApplication
                .delete(id)
                .then((response) => {
                  this.getAllSchools()
                  this.$snotify.success("SchoolPay application deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>